import React, { useState, useEffect } from "react";
import { Card, Form, Row, Col } from "react-bootstrap";

function RequestTypes(props) {
  const { requestTypes, onAccessRequestType, onUpdateRequestType } = props;
  const [checkedRequestTypes, setCheckedRequestTypes] = useState([]);
  const [relationshipType, setRelationshipType] = useState([]);
  const behaviouralAdvertising = `"Cross-context behavioral advertising” means the targeting of advertising
      to a consumer based on the consumer's personal information obtained from
      the consumer's activity across businesses, distinctly-branded websites,
      applications, or services, other than the business, distinctly-branded
      website, application or service with which the consumer intentionally
      interacts.`;

  const targetAdvertising = `"Targeted advertising" means displaying advertisements to a consumer where
      the advertisement is selected based on personal data obtained or inferred
      from that consumer's activities over time and across nonaffiliated
      Internet web sites or online applications to predict such consumer's
      preferences or interests.`;

  const handleChange = (e) => {
    if (e.target?.value) {
      e.stopPropagation();
    }
    const inputValue = e.target?.value || e;
    const indexVal = relationshipType.indexOf(e.target?.id);
    if (indexVal === -1) {
      setRelationshipType([...relationshipType, e.target?.id]);
    } else {
      setRelationshipType(
        relationshipType.filter((ele) => ele !== e.target?.id)
      );
    }
    console.log("requesttype clicked", inputValue);
    let currentRequestTypes = [...checkedRequestTypes];
    if (currentRequestTypes.indexOf(inputValue) === -1) {
      currentRequestTypes = [...currentRequestTypes, inputValue];
    } else {
      currentRequestTypes = currentRequestTypes.filter(
        (requestType) => requestType !== inputValue
      );
    }

    console.log("currentRequestTypes", currentRequestTypes);
    setCheckedRequestTypes(currentRequestTypes);
    props.onRequestTypesChange(currentRequestTypes);
  };

  useEffect(() => {
    if (relationshipType) {
      const checkElements = ["access", "correction"];
      if (checkElements.every((color) => relationshipType.includes(color))) {
        onUpdateRequestType(true);
        onAccessRequestType(true);
      } else if (relationshipType.includes("correction")) {
        onUpdateRequestType(true);
        onAccessRequestType(false);
      } else if (relationshipType.includes("access")) {
        onAccessRequestType(true);
        onUpdateRequestType(false);
      } else {
        onUpdateRequestType(false);
        onAccessRequestType(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relationshipType]);

  return (
    <>
      <p className="my-3">
        <strong className="label-text">
          Contact Reason: <span className="text-danger">*</span>
        </strong>
      </p>
      <Row xs={1} className="g-3 mb-4">
        {requestTypes &&
          requestTypes.map((requestType, index) => {
            return (
              <Col key={`${requestType.value}`}>
                <Card className="card-requestType" key={`${requestType.value}`}>
                  <Card.Body>
                    <Form.Check>
                      <Form.Check.Input
                        type="checkbox"
                        value={`${requestType.value}`}
                        id={`${requestType.value}`}
                        onClick={handleChange}
                      />

                      {requestType.value === "optOut-data-processing" ? (
                        <Form.Check.Label className="h6">
                          Opt-out of sharing for{" "}
                          <>
                            <span
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title={behaviouralAdvertising}
                            >
                              cross-context behavioral advertising
                            </span>
                            /
                            <span
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title={targetAdvertising}
                            >
                              targeted advertising
                            </span>
                          </>
                        </Form.Check.Label>
                      ) : (
                        <Form.Check.Label className="h6">
                          {requestType.description}
                        </Form.Check.Label>
                      )}
                    </Form.Check>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
      </Row>
    </>
  );
}

export default RequestTypes;
